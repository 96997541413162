import { Link } from "gatsby"
import React from "react"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Image from "../components/Image"
import Layout from "../components/Layout"
import Action from "../components/Resource/Action"
import Section from "../components/Section"
import SEO from "../components/Seo"
import Video from "../components/Video"
import bodyConfidenceMasterClassPDF from "../docs/Unilever-AmazingMe-MasterclassEdGuide.pdf"
import ctaArrow from "../images/theme/img-cta-arrow-white.png"
import buildingBodyConfidence from "../images/theme/img-masterclass-body-confidence.jpg"
import bullyingMasterClass from "../images/theme/img-masterclass-bullying-teasing.jpg"
import FooterCallout from "../partials/footerCallout"

const MasterClass = () => (
  <Layout>
    <SEO title="Master Class Resources" />
    {/***************  HERO ***************/}
    <Section className="hero hero__master-class">
      <Hero
        title={
          <>
            <span className={"header-bar--yellow"}>Masterclasses</span>
          </>
        }
        body="Explore how to bring body confidence to your classroom."
        image="img-Masterclasses-hero-circle.png"
      />
    </Section>

    {/***************  INTRODUCTION TEXT ***************/}
    <Section className="">
      <Container>
        <p className="easy-reading-font">
          Join <em>Amazing Me</em> in their Master Class Series, designed to
          help educators explore how to use <em>Amazing Me</em> resources in
          their classroom. These videos will provide educators with the skills
          to encourage kindness and open discourse while discussing topics
          including body dissatisfaction and bullying with elementary students.
        </p>
        <p className="easy-reading-font">
          Hosted by Marisol Perez, a clinical psychologist and researcher at
          Arizona State University and The Institute for Research and Education
          Advancing Children’s Health (REACH). Dr. Perez has dedicated her
          career to assisting future generations to be less focused on
          appearance and more focused on respecting, liking, and appreciating
          their bodies.
        </p>
      </Container>
    </Section>

    {/***************  CONFRONTING COMPARISONS MASTER CLASS ***************/}
    <Section className="master-class__video-section">
      <Container>
        <h2>Confronting Comparisons to Build Body Confidence</h2>
        <p>
          Every student has unique skills, abilities, and ideas to offer the
          world. Teach your students the value of their own unique identities by
          introducing concepts like the healthy ideal vs. the appearance ideal,
          positive body talk, and the influence of social and celebrity media on
          body image.
        </p>
        <Row>
          <Column span={6}>
            <Video
              guidSrc={"0decb1f7-ddc6-4673-88d1-f5ee0165516f"}
              poster={buildingBodyConfidence}
              title="Building Body Confidence"
              style={{
                aspectRatio: "16/9",
                height: "auto",
              }}
            />
          </Column>
          <Column span={6}>
            <h2>Master Class 1</h2>
            <h2>Building Body Confidence</h2>
            <p>
              Dr Perez will discuss tips to help address body confidence issues
              in the classroom while providing the safe, mindful space necessary
              for students to flourish and learn social-emotional skills.
              Utilize the educator guide for additional information and tools to
              help you succeed.
            </p>
            <Action
              label="Watch Video"
              type="video"
              guidSrc={"0decb1f7-ddc6-4673-88d1-f5ee0165516f"}
              poster={buildingBodyConfidence}
              title="Building Body Confidence"
            >
              Watch Video
            </Action>
            <Action
              label="Body Confidence Companion Guide"
              type="download"
              size="PDF 2.5MB"
              title=""
              target="_blank"
              rel="noreferrer noopener"
              path={bodyConfidenceMasterClassPDF}
            >
              Body Confidence Companion Guide
            </Action>
          </Column>
        </Row>
      </Container>

      {/***************  BULLYING & TEASING MASTER CLASS ***************/}
      <Container>
        <h2 style={{ paddingTop: "2rem" }}>
          Bullying &amp; Teasing and Their Effect on Body Confidence
        </h2>
        <p>
          Bullying and teasing can have a profound impact on the way students
          view themselves and each other. Educators will learn how to teach
          students the different strategies for confronting bullying and how to
          stand up for others.
        </p>
        <Row>
          <Column span={6}>
            <Video
              guidSrc={"fe43af6f-fc26-4831-86ef-e9b55002d23e"}
              poster={bullyingMasterClass}
              title="Eliminating Weight-based Bullying and Teasing"
              style={{
                aspectRatio: "16/9",
                height: "auto",
              }}
            />
          </Column>
          <Column span={6}>
            <h2>Master Class 2</h2>
            <h2>Eliminating Weight-based Bullying &amp; Teasing</h2>
            <p>
              Dr. Perez introduces issues related to weight-based bullying and
              teasing, including the consequences these events can have on
              elementary-aged students. Utilize the educator guide for
              additional information and tools to help you succeed.
            </p>
            <Action
              label="Watch Video"
              type="video"
              guidSrc={"fe43af6f-fc26-4831-86ef-e9b55002d23e"}
              poster={bullyingMasterClass}
              title="Eliminating Weight-based Bullying and Teasing"
            >
              Watch Video
            </Action>
            <Action
              label="Body Confidence Companion Guide"
              type="download"
              size="PDF 2.5MB"
              title=""
              target="_blank"
              rel="noreferrer noopener"
              path={bodyConfidenceMasterClassPDF}
            >
              Body Confidence Companion Guide
            </Action>
          </Column>
        </Row>
      </Container>
    </Section>

    <Section className="master-class-cta">
      <Container>
        <Link to="/classroom-resources" className="cta">
          Explore Classroom Resources!
          <img
            src={ctaArrow}
            alt={"Go to Family Resources"}
            className={"cta__arrow"}
          />
        </Link>
      </Container>
    </Section>

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className={"squiggle"}>
      <Container>
        <h3>Explore Additional Resources</h3>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-ATS.png"}
            />
            <h4 className={"bold-text"}>Animated Topic Series</h4>
            <p>
              The Amazing Me: Animated Topic Series encourages young people to
              realize their full potential and improve their body confidence and
              self-esteem.
            </p>
            <Link to={"/power-of-confidence/"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-vft.png"}
            />
            <h4 className={"bold-text"}>Virtual Field Trip</h4>
            <p>
              Help students learn to appreciate their bodies, get smarter about
              images they see online, and build strategies to fight weight-based
              bullying and teasing.
            </p>
            <Link to={"/virtual-field-trip"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className=" midgray-background body-confidence-fact--vft">
      <Container>
        <Row>
          <Column span={7}>
            <p>
              Among children, weight-based bias is primarily expressed through
              teasing, bullying, and other victimization.
            </p>

            <p>
              &mdash;<em>Journal of Youth and Adolescence</em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-3.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)

export default MasterClass
